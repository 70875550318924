import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

import { Step } from "../models/Step";
import { Teacher } from "../models/Teacher";
import { Exercise } from "../models/Exercise";
import { MenuItem } from 'primeng/api';
import { AuthService } from '../services/auth.service';
import { StepService } from '../services/step.service';
import { ExerciseService } from '../services/exercise.service';
import { LanaService } from '../services/lana.service';
import { MessageService } from 'primeng/api';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";



//import { Message } from 'primeng/api';


@Component({
  selector: 'app-exercise-xr',
  templateUrl: './exercise-xr.component.html',
  styleUrls: ['./exercise-xr.component.css']
})
export class ExerciseXRComponent implements OnInit {

  //@Input() exercise: Exercise;

  exercise: Exercise;
  teacher: Teacher;
  nSteps: any;
  mySteps: any;
  exerciseId: string;
  myStep: any;
  stepsMenu: MenuItem[];
  title: string = "";
  description: string = "";

  activeIndex: number = 0;
  step: Step = new Step();
  changeStep: boolean = false;
  vrMode: boolean = false;
  newStepPosition: any;

  //msgs: Message[] = [];

  constructor(private authService: AuthService,
    private stepService: StepService,
    private exerciseService: ExerciseService,
    private router: Router,
    private lanaService: LanaService,
    private route: ActivatedRoute, private messageService: MessageService,
    private translate: TranslateService) {

    this.route.params.subscribe(params => {
      this.exerciseId = params['exerciseId'];
      //var name = params['name']

      let newStep: any = [];
      newStep.title = "Insert title";
      newStep.description = "Insert description";
      newStep.help = "Insert help";
      newStep.initialModels = [];
      newStep.correctModels = [];
      newStep.incorrectModels = [];
      newStep.order = 0;
      newStep.exerciseId = "this.exerciseId";

      this.myStep = newStep;
    })

  }

  ngOnInit() {
    //ToDo: read the steps of the exercise
    //this.nSteps = 0;
    //this.lanaService.resetScene();
    this.lanaService.resetScene(function (p) { }, this);
    this.myStep = undefined;
    this.authService.getLogged().subscribe(
      teacher => {
        this.teacher = teacher;
      }
    );
    //console.log("El ejercicio: ", this.exerciseId);
    this.exerciseService.getByExerciseId(this.exerciseId).subscribe(
      exercise => {
        //console.log("me devuelve el ejercicio: ", exercise);
        this.exercise = exercise;
        this.title = exercise[0].title;
        this.description = exercise[0].description;
        this.getSteps(this.exerciseId);
        //this.myStep = this.mySteps[0];
      }
    );

  }


  getSteps(exerciseId) {
    //search projects where teacher.id is editor
    this.mySteps = [];
    let myOptions: any[] = [];
    this.stepService.getAllFromExercise(exerciseId).subscribe(
      steps => {
        this.mySteps = steps;
        this.mySteps.sort(function (a, b) { return (a.order - b.order) });
        this.nSteps = this.mySteps.length;

        if (this.nSteps === 0) {
          let newStep: any = [];
          newStep.title = "Insert title";
          newStep.description = "Insert description";
          newStep.help = "Insert help";
          newStep.initialModels = [];
          newStep.correctModels = [];
          newStep.incorrectModels = [];
          newStep.helpFiles = [];
          newStep.order = 0;
          newStep.exerciseId = this.exerciseId;


          this.stepService.newStep(newStep).subscribe(step => {
            this.mySteps.push(step);
            this.ngOnInit();

          })
        }

        for (var i = 0; i < this.mySteps.length; i++) {
          myOptions[i] = {
            command: (event: any) => {
              this.activeIndex = event.index;
              this.myStep = this.mySteps[event.index];
              this.ngOnInit();
            }
          };
        }


        this.stepsMenu = myOptions;
        this.myStep = this.mySteps[this.activeIndex];
        console.log("webxr: ", this.myStep);

      });
  }

  showCreateStepPositionOption() {
    this.newStepPosition = this.nSteps + 1;
    this.changeStep = true;
  }

  cancelNewStep() {
    this.changeStep = false;
  }



  /*showInfo(summary: string, detail: string) {
    console.log("SHOWINFO");
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: summary, detail: detail });
  }*/

  createStep() {
    //hide modal
    if (isNaN(this.newStepPosition)) {
      let possibleStepPosition = this.nSteps + 1;
      let summary = "Error";
      let detail = "The step position has to be a number between 1 and " + possibleStepPosition;
      this.messageService.add({ severity: 'error', summary: summary, detail: detail });
      this.newStepPosition = this.nSteps + 1;
    }
    else if (this.newStepPosition < 1 || this.newStepPosition > (this.nSteps + 1)) {
      //this.changeStep = false;
      let possibleStepPosition = this.nSteps + 1;
      let summary = "Error";
      let detail = "The step position has to be between 1 and " + possibleStepPosition;
      this.messageService.add({ severity: 'error', summary: summary, detail: detail });
      this.newStepPosition = this.nSteps + 1;
      //this.showInfo(summary, detail);
    }
    else {
      this.changeStep = false;
      this.nSteps = this.mySteps.length;

      if (this.newStepPosition === (this.nSteps + 1)) {
        //last position
        let newStep: any = [];
        newStep.title = "Insert title";
        newStep.description = "Insert description";
        newStep.help = "Insert help";
        //get last last step models
        let lastStep = this.mySteps[this.nSteps - 1];
        let previousModels: any[] = [];
        for (let model of lastStep.initialModels) {
          previousModels.push(model);
        }
        for (let model of lastStep.correctModels) {
          previousModels.push(model);
        }
        newStep.initialModels = previousModels;
        newStep.correctModels = [];
        newStep.incorrectModels = [];
        newStep.exerciseId = this.exerciseId;

        newStep.order = this.nSteps;

        this.stepService.newStep(newStep).subscribe(step => {
          this.mySteps.push(step);
          this.activeIndex = this.mySteps.length - 1;
          this.ngOnInit();
        });


      }
      else {
        //intermediate position
        this.activeIndex = this.newStepPosition - 1;
        //console.log("this.activeIndex:", this.activeIndex);
        let newStep: any = [];
        newStep.title = "Insert title";
        newStep.description = "Insert description";
        newStep.help = "Insert help";
        //get last last step models
        let lastStep = this.mySteps[this.newStepPosition - 1];
        let previousModels: any[] = [];
        for (let model of lastStep.initialModels) {
          previousModels.push(model);
        }
        for (let model of lastStep.correctModels) {
          previousModels.push(model);
        }
        newStep.initialModels = previousModels;
        newStep.correctModels = [];
        newStep.incorrectModels = [];
        newStep.exerciseId = this.exerciseId;

        newStep.order = this.newStepPosition - 1;

        //change the order of the following steps
        this.stepService.renumberStepsAdd(this.exerciseId, this.newStepPosition - 1);

        this.stepService.newStep(newStep).subscribe(step => {
          this.mySteps.push(step);
        });
        this.ngOnInit();

      }


    }


  }

  goToBack() {
    console.log("goToBack");
    this.activeIndex = this.activeIndex - 1;
    this.myStep = this.mySteps[this.activeIndex];
    this.ngOnInit();

  }

  goToNext() {
    console.log("goToNextack");
    this.activeIndex = this.activeIndex + 1;
    this.myStep = this.mySteps[this.activeIndex];
    this.ngOnInit();

  }

  checkFirstStep() {
    return (this.activeIndex === 0);
  }

  checkLastStep() {
    return (this.activeIndex === this.nSteps - 1);
  }

  checkVRAvailable() {
    //TODO: check VR isAvailable
    return true;
  }

  VRMode() {
    //document.getElementById('visor').requestFullscreen();
    // this.el.nativeElement.requestFullscreen();
    this.vrMode = this.lanaService.setVRMode();
    if (!this.vrMode) {
      let summary = this.translate.instant('VR_ERROR');
      let detail = this.translate.instant('Check_VR_device');
      this.messageService.add({ severity: 'error', summary: summary, detail: detail });
    }
  }

  fullScreen() {
    this.vrMode = !this.vrMode;
  }



}

