import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { RestService } from './rest.service';
import { StoreI } from '../models/StoreI';
import { UserService } from './user.service';

@Injectable()
export class StoreService implements StoreI {
  private TOKEN_NAME: string = environment.tokenName;

  constructor(http: Http, private userService: UserService) {
    this.init();
  }

  init(): void { }

  reset(): void { }
}
