import { Component, OnInit, Input } from '@angular/core';

import { StepService } from '../services/step.service';

import { ExerciseComponent } from '../exercise/exercise.component'
import { Router } from '@angular/router';

import { Step } from "../models/Step";

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {
  @Input() step: any;
  @Input() exercise: any;
  @Input() vrMode: any;
  model: string;

  showHelp: boolean = false;
  currentTutorialPage: number;// = 2;
  tutorialNumPages: number = 0;
  pdfSrc: string;

  showText: boolean = false;
  textArea: string = "";

  constructor() { }

  ngOnInit() {
  }

  public onModel(myModel: string) {
    this.model = myModel;
  }

  update() {
    this.exercise.getSteps(this.exercise.exerciseId);
    this.exercise.activeIndex = this.exercise.activeIndex - 1;
    if (this.exercise.activeIndex < 0) {
      this.exercise.activeIndex = 0;
    }
    this.exercise.ngOnInit();
  }

  onPlayAction(event) {
    this.step.interactiveElements.forEach(element => {
      if (element.name === event) {
        if (element.action !== "" && element.type === 'sound') {
          let audio = new Audio(element.action.url);
          audio.play();
        }
        if (element.action !== "" && element.type === 'file') {
          this.pdfSrc = element.action.url;
          console.log("this.pdf: ", this.pdfSrc);
          this.showHelp = true;
        }
        if (element.action !== "" && element.type === 'text') {
          //window.alert(element.action);
          this.showText = true;
          this.textArea = element.action;

        }
      }
    });
  }

  prevTutorialPage() {
    this.currentTutorialPage = this.currentTutorialPage - 1;
  }

  nextTutorialPage() {
    this.currentTutorialPage = this.currentTutorialPage + 1;
  }

  checkFirstTutorialPage() {
    if (this.currentTutorialPage === 1) {
      return true;
    }
  }

  checkLastTutorialPage() {
    if (this.currentTutorialPage === this.tutorialNumPages) {
      return true;
    }
  }

  stateRender(event) {
    let info = event._pdfInfo;
    this.tutorialNumPages = info.numPages;
  }

  closeHelpPanel() {
    this.showText = false;
  }

}
