import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from "./../../environments/environment";
import { LanaService } from '../services/lana.service';
import ThreeMeshUI from 'three-mesh-ui';

import $ from "jquery";
import { getOrCreateNodeInjectorForNode } from '@angular/core/src/render3/di';

@Component({
  selector: 'app-step-xr',
  templateUrl: './step-xr.component.html',
  styleUrls: ['./step-xr.component.css']
})
export class StepXRComponent implements OnInit {

  @Input() step: any;
  @Input() exercise: any;
  @Input() vrMode: any;
  model: string;

  @Output() public modelEmitter = new EventEmitter<string>();
  @Output() public goToNextStep = new EventEmitter<void>();
  @Output() public goToBackStep = new EventEmitter<void>();

  environment: any = environment;

  description: string;
  title: string;
  help: string;
  order: number;
  id: string;
  addTexture: boolean = false;
  addModel: boolean = false;
  daeModel: string;

  loading: boolean = false;
  nloaded: number = 0;


  selectedFile: string;
  //uploadedFiles: any[] = [];

  initialModels: any[];// = [];
  correctModels: any[];// = [];
  incorrectModels: any[];// = [];
  helpFiles: any[];
  stepOptions: any[] = [];
  selectedOptions: any[];
  lastSelectedOptions: any[] = [];

  uploadedFileInfo: any;
  uploadedDAE: any;
  textureList: any[] = [];
  texture: string = "";

  showModel: boolean = true;
  showHelp: boolean = false;
  pdfSrc: string;
  helpType: string = "";

  helpFileSrc: string = '';
  tutorialNumPages: number = 0;
  currentTutorialPage: number;

  gp: any;
  isgamepad: boolean = false;

  isShowingInfoPanel: boolean = false;
  isButton0Pressed: boolean = false;
  isButton1Pressed: boolean = false;
  isButton2Pressed: boolean = false;
  isButton3Pressed: boolean = false;
  isButton4Pressed: boolean = false;
  isButton5Pressed: boolean = false;
  isButton6Pressed: boolean = false;
  isButton7Pressed: boolean = false;
  isButton8Pressed: boolean = false;
  isButton9Pressed: boolean = false;
  isButton10Pressed: boolean = false;
  isButton11Pressed: boolean = false;
  isButton12Pressed: boolean = false;
  isButton13Pressed: boolean = false;
  isButton14Pressed: boolean = false;
  isButton15Pressed: boolean = false;

  isSelectedObjectToMove: boolean = false;
  isSelectedObjectToRotate: boolean = false;
  selectedObject: any;

  //correctModelsSubscription$: Observable<any>;

  exerciseId: string;

  //msgs: Message[] = [];

  constructor(private lanaService: LanaService) {

    /*console.log("window add event listener gamepadconnected");
    window.addEventListener("gamepadconnected", function (e) {
      console.log("gamepadconnected, ", e);
    });*/
  }

  ngOnInit() {
    //$("#viewport").width = 100;
    //document.getElementById("viewport").style.width = '100%';
    let vrMode = this.lanaService.setVRMode();
  }

  ngDoCheck() {
    //console.log("ngDoCheck");
    this.gp = navigator.getGamepads()[0];
    //console.log("thi.gp: ", this.gp);
    let gamepad = navigator.getGamepads()[0];
    /*if (this.gp) {
      this.isgamepad = true;
    }*/
    if (gamepad) {
      //console.log("gamepad: ", gamepad);
      //A Button
      //console.log("gamepad loop ");
      //gamepad = navigator.getGamepads()[0];
      if (gamepad.buttons[0].pressed == true && !this.isButton0Pressed) {
        console.log("button 0 pressed", this.gp.buttons[0].value);
        this.isButton0Pressed = true;
        //this.showInfoPanel();
        this.onPlayAction();
        //this.selectObject();
      }
      if (gamepad.buttons[0].pressed == false && this.isButton0Pressed) {
        this.isButton0Pressed = false;
      }
      //B button
      /*if (gamepad.buttons[1].pressed == true && this.isButton1Pressed) {
        console.log("button 1 pressed");
        this.isButton1Pressed = true;
        this.getIntersectedObject();
      }*/
      if (this.gp.buttons[1].value > 0) {
        console.log("button 1 pressed");
        this.isButton1Pressed = true;
        this.getIntersectedObjectToMove();
      }
      if (this.gp.buttons[1].value == 0 && this.isButton1Pressed) {
        console.log("******************button 1 soltado");
        this.isButton1Pressed = false;
      }
      //X button
      if (gamepad.buttons[2].value > 0) {
        console.log("button 2 pressed");
        this.isButton2Pressed = true;
        this.deleteIntersectedObject();
      }
      if (gamepad.buttons[2].value == 0 && this.isButton2Pressed) {
        this.isButton2Pressed = false;
      }
      //y button
      if (gamepad.buttons[3].value > 0 || this.gp.buttons[3].pressed == true) {
        console.log("button 3 pressed *********************** ############");
        this.showHelpPanel();
      }
      if (gamepad.buttons[4].value > 0 && !this.isButton4Pressed) {
        console.log("button 4 pressed *********************** ############");
        this.isButton4Pressed = true;
        this.goToPrevious();

      }
      if (gamepad.buttons[4].value == 0 && this.isButton4Pressed) {
        console.log("button 4 pressed *********************** ############");
        this.isButton4Pressed = false;
      }
      if (gamepad.buttons[5].value > 0 && !this.isButton5Pressed) {
        console.log("button 5 pressed *********************** ############");
        this.isButton5Pressed = true;
        this.goToNext();
      }
      if (gamepad.buttons[5].value == 0 && this.isButton5Pressed) {
        console.log("button 5 pressed *********************** ############");
        this.isButton5Pressed = false;
      }
      if (gamepad.buttons[6].value > 0 || gamepad.buttons[6].pressed == true) {
        console.log("button 6 pressed *********************** ############");
      }

      if (gamepad.buttons[7].value > 0 || gamepad.buttons[7].pressed == true) {
        console.log("button 7 pressed *********************** ############");
      }
      if (gamepad.buttons[8].value > 0 || gamepad.buttons[8].pressed == true) {
        console.log("button 8 pressed *********************** ############");
      }
      if (gamepad.buttons[9].value > 0 || gamepad.buttons[9].pressed == true) {
        console.log("button 9 pressed *********************** ############");
      }
      if (gamepad.buttons[10].value > 0 || gamepad.buttons[10].pressed == true) {
        console.log("button 10 pressed *********************** ############");
      }
      if (gamepad.buttons[11].value > 0 || gamepad.buttons[11].pressed == true) {
        console.log("button 11 pressed *********************** ############");
      }
      if (gamepad.buttons[12].value > 0) {
        console.log("button 12 pressed *********************** ############");
        this.getIntersectedObjectToRotate();
      }
      if (gamepad.buttons[13].value > 0 || gamepad.buttons[13].pressed == true) {
        console.log("button 13 pressed *********************** ############");
      }
      if (gamepad.buttons[14].value > 0) {
        console.log("button 14 pressed");
        this.isButton14Pressed = true;
        this.showInfoPanel();
      }
      /*if (this.gp.buttons[14].value == 0 && this.isButton14Pressed) {
        this.isButton14Pressed = false;
      }*/
      if (gamepad.buttons[15].value > 0) {
        console.log("button 15 pressed");
        this.removeInfoPanel();
      }

      if (gamepad.axes[0] != 0 && this.isSelectedObjectToMove) {
        //this.move
        console.log("move", gamepad.axes[0]);
        this.moveObjToX(gamepad.axes[0]);
      }
      else if (gamepad.axes[0] != 0 && this.isSelectedObjectToRotate) {
        //this.move
        console.log("rotate");
        this.rotateObjX(gamepad.axes[0]);
      }
      if (gamepad.axes[1] != 0 && this.isSelectedObjectToMove) {
        //this.move
        console.log("move", gamepad.axes[0]);
        this.moveObjToY(gamepad.axes[1]);
      }
      else if (gamepad.axes[1] != 0 && this.isSelectedObjectToRotate) {
        //this.move
        console.log("rotate");
        this.rotateObjY(gamepad.axes[1]);
      }
      if (gamepad.axes[2] != 0 && this.isSelectedObjectToMove) {
        //this.move
        console.log("move", gamepad.axes[0]);
        this.moveObjToZ(gamepad.axes[2]);
      }
      else if (gamepad.axes[2] != 0 && this.isSelectedObjectToRotate) {
        //this.move
        console.log("rotate");
        this.rotateObjZ(gamepad.axes[2]);
      }

    }
    else {
      //this.lanaService.addContainer();
    }
    //this.gp = undefined;

    // Filter out only the buttons which are pressed


  }

  ngAfterContentChecked() {
    /*console.log("ngAfterContentChecked");
    let gamepad = navigator.getGamepads()[0];
    console.log("g", gamepad);*/

  }

  ngOnChanges(changes) {
    //console.log("ngOnChanges");
    let myStep = changes.step.currentValue;
    if (myStep) {
      this.title = myStep.title;
      this.description = myStep.description;
      this.exerciseId = myStep.exerciseId;
      this.help = myStep.help;
      this.order = myStep.order;
      this.id = myStep.id;
      this.initialModels = myStep.initialModels;
      this.correctModels = myStep.correctModels;
      this.incorrectModels = myStep.incorrectModels;
      this.helpFiles = myStep.helpFiles;
      if (this.helpFiles === undefined) { this.helpFiles = [] }

      //this.stepOptions = this.correctModels;
      this.correctModels.forEach(element => {
        this.stepOptions.push(element);
      });
      // + this.incorrectModels;
      this.incorrectModels.forEach(element => {
        this.stepOptions.push(element);
      });

      //get unsorted options
      this.stepOptions = this.stepOptions.sort(function () { return Math.random() - Math.random() });

      this.loadInitialModels();
      this.loadStepOptions();
      this.createUI();


    }

  }

  /*isPressed({ button: { pressed } }) {
    console.log("isPressed:");
    return !!pressed;
  }*/

  public onModel(myModel: string) {
    this.model = myModel;
  }

  update() {
    this.exercise.getSteps(this.exercise.exerciseId);
    this.exercise.activeIndex = this.exercise.activeIndex - 1;
    if (this.exercise.activeIndex < 0) {
      this.exercise.activeIndex = 0;
    }
    this.exercise.ngOnInit();
  }

  onPlayAction() {
    var obj = this.lanaService.getIntersectedObject(false);
    console.log("objeto seleccionado: ", obj);
    while (obj !== undefined && obj !== null) {
      if (obj.name === "XY" || obj.name === "YZ" || obj.name === "XZ"
        || obj.name === "X" || obj.name === "Y" || obj.name === "Z") {
        return;
      }
      var event = obj.name;
      console.log("evento de: ", event);
      this.step.interactiveElements.forEach(element => {
        if (element.name === event) {
          if (element.action !== "" && element.type === 'sound') {
            let audio = new Audio(element.action.url);
            audio.play();
          }
          if (element.action !== "" && element.type === 'file') {
            console.log("abrir pdf");
            this.pdfSrc = element.action.url;
            this.showHelp = true;
          }
          if (element.action !== "" && element.type === 'text') {
            //window.alert(element.action);
            //console.log(element.action);
            if (this.isShowingInfoPanel) {
              this.lanaService.deleteUIInfo();
              this.isShowingInfoPanel = false;
            }
            this.lanaService.addUIText(element.action);
            this.isShowingInfoPanel = true;
          }
        }
      });
      obj = obj.parent;
    }

  }

  loadInitialModels() {
    let numModels = this.initialModels.length;// + this.correctModels.length;
    let sum = 100 / numModels;
    let nModel = 1;
    let matrix;

    if (this.initialModels.length > 0) {
      for (let initialModel of this.initialModels) {
        let params = {
          this: this,
          nModel: nModel,
          numModels: numModels
        }
        this.loading = true;
        let mydaeObj = initialModel.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        if (initialModel.matrixWorld === undefined || initialModel.matrixWorld === null) {
          matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
        }
        else {
          matrix = initialModel.matrixWorld;
        }
        let rename = mydaeObj.substring(mydaeObj.indexOf("/api/"));
        mydaeObj = environment.apiEndpoint + rename;
        this.lanaService.addModel(mydaeObj, matrix, null, params);
      }
    }
  }

  loadStepOptions() {
    let numModels = this.stepOptions.length;
    let nModel = 1;
    let matrix;

    if (this.stepOptions.length > 0) {
      for (let model of this.stepOptions) {
        let params = {
          this: this,
          nModel: nModel,
          numModels: numModels
        }
        this.loading = true;
        let mydaeObj = model.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        if (model.matrixWorld === undefined || model.matrixWorld === null) {
          matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
        }
        else {
          matrix = model.matrixWorld;
        }
        let rename = mydaeObj.substring(mydaeObj.indexOf("/api/"));
        mydaeObj = environment.apiEndpoint + rename;
        this.lanaService.addModel(mydaeObj, matrix, null, params);
      }
    }
  }

  createUI() {
    const container = new ThreeMeshUI.Block({
      height: 1.5,
      width: 1
    });

    container.position.set(0, 1, -1.8);
    container.rotation.x = -0.55;
    this.lanaService.addUI();
    //scene.add( container );
  }

  showInfoPanel() {
    //showInfo

    if (!this.isShowingInfoPanel) {
      console.log("showInfoPanel");
      this.lanaService.addUIInfo(this.title, this.description);
      this.isShowingInfoPanel = true;
    }
    /*else {
      console.log("deleteInfoPanel");
      this.lanaService.deleteUIInfo();
      this.isShowingInfoPanel = false;
    }*/
  }

  removeInfoPanel() {

    if (this.isShowingInfoPanel) {
      this.lanaService.deleteUIInfo();
      this.isShowingInfoPanel = false;
    }

  }

  showHelpPanel() {
    console.log("showHelpPanel");

  }

  //button 4
  goToNext() {
    console.log("goToNext emit");
    this.goToNextStep.emit();
  }

  //button 5
  goToPrevious() {
    console.log("goToPrevious");
    this.goToBackStep.emit();
  }

  //button
  getIntersectedObjectToMove() {
    this.lanaService.setControlMode('translate');
    var obj = this.lanaService.getIntersectedObject(true);
    //console.log("objeto seleccionado: ", obj);
    if (obj !== null) {
      this.isSelectedObjectToMove = true;
      this.selectedObject = obj;
    }
    else {
      this.isSelectedObjectToMove = false;
      this.selectedObject = null;
    }


  }

  //button 12
  getIntersectedObjectToRotate() {
    this.lanaService.setControlMode('rotate');
    var obj = this.lanaService.getIntersectedObject(true);
    //console.log("objeto seleccionado: ", obj);
    if (obj !== null) {
      this.isSelectedObjectToRotate = true;
      this.selectedObject = obj;
    }
    else {
      this.isSelectedObjectToRotate = false;
      this.selectedObject = null;
    }


  }

  deleteIntersectedObject() {
    this.lanaService.deleteIntersectedObject();
    this.isSelectedObjectToMove = false;
    this.isSelectedObjectToRotate = false;

  }

  moveObjToX(val) {
    if (this.selectedObject != null || this.selectedObject !== undefined) {
      this.lanaService.moveObjToX(this.selectedObject, val);
    }
  }

  moveObjToY(val) {
    if (this.selectedObject != null || this.selectedObject !== undefined) {
      this.lanaService.moveObjToZ(this.selectedObject, val);
    }
  }

  moveObjToZ(val) {
    if (this.selectedObject != null || this.selectedObject !== undefined) {
      this.lanaService.moveObjToZ(this.selectedObject, val);
    }
  }

  rotateObjX(val) {
    if (this.selectedObject != null || this.selectedObject !== undefined) {
      this.lanaService.rotateObjX(this.selectedObject, val);
    }
  }

  rotateObjY(val) {
    if (this.selectedObject != null || this.selectedObject !== undefined) {
      this.lanaService.rotateObjY(this.selectedObject, val);
    }
  }

  rotateObjZ(val) {
    if (this.selectedObject != null || this.selectedObject !== undefined) {
      this.lanaService.rotateObjZ(this.selectedObject, val);
    }
  }



}
