import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { StepComponent } from '../step/step.component';
import { Observable } from 'rxjs';
import $ from "jquery";
import { MessageService } from 'primeng/api';

import { LanaService } from '../services/lana.service';

import { TranslateService } from "@ngx-translate/core";

declare var LANA3D: any;
declare var VIEWER: any;

@Component({
  selector: 'app-lana',
  templateUrl: './lana.component.html',
  styleUrls: ['./lana.component.css'],
  //encapsulation: ViewEncapsulation.Native
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LanaComponent implements OnInit {

  @Input() vrMode: any;
  @Output() public playAction = new EventEmitter<string>();

  editor: any;
  scene: any;
  mesh: any;
  //@Input() stepComponent: StepComponent;

  myModel$: Observable<string>;
  model: string = "";

  //visor: any;

  moveChecked: boolean = true;
  rotateChecked: boolean = false;
  selectedObject: boolean = false;
  myObject: any;
  prevX: any = 0;
  prevY: any = 0;


  @ViewChild('lanaContainer') lanaContainer: ElementRef;
  @ViewChild('visor') visor: ElementRef;


  constructor(private lanaService: LanaService, private messageService: MessageService, private translate: TranslateService) {
  }

  ngOnInit() {

    //this.clearScene();
    this.lanaService.getEditor().subscribe(editor => {
      if (editor) {
        this.editor = editor;
        this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
        this.editor.resize();

        /*this.editor.on('lanaContainer', function (data) {
          console.log(data);
          console.log("-------- SEÑAL next_step");

        });

        $("#lanaContainer").on("next_step", function () {
          console.log("next_step lanacontainer señal");
        });

        $("#lanaContainer").on("click", function () {
          console.log("click lana container señal");
        });

        $("#lanaContainer").click(function (event, a, b) {
          console.log("event:", event);
          console.log("event click lanacontainer");
        })*/
      }
    });

    console.log("this.vrMode: ", this.vrMode);
    if (this.vrMode) {
      console.log("#viewport");
      $("body").css("--my-height", "100%");
      $("body").css("--my-width", "100%");
    }
    else {
      console.log("No VR");
      $("body").css("--my-height", "calc(100% - 133px)");
      $("body").css("--my-width", "70%");
    }


    /*this.editor = VIEWER.Editor.create();
    this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
    this.editor.resize();*/
    /*
    this.scene = LANA3D.NodeManager.createNode("scene");
    this.mesh = LANA3D.NodeManager.createNode("mesh");*/

    //this.editor.setScene(this.scene);

    /*this.editor = VIEWER.Editor.create();
    this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
    this.editor.resize();
    this.scene = LANA3D.NodeManager.createNode("scene");
    this.editor.setScene(this.scene);
    LANA3D.AssetManager.loadBundle("models/test2.dae", result => {
      let o = result.scene;
      o.getLocalTransform().getPosition().add(new LANA3D.Vector3(20, 0, 0));
      this.scene.addChild(o);
    });*/

    /*this.lanaService.getModel().subscribe(model => {
      console.log("getModel: ", model);
      if (model) {
        LANA3D.AssetManager.loadBundle(model, result => {
          let o = result.scene;
          o.getLocalTransform().getPosition().add(new LANA3D.Vector3(20, 0, 0));
          this.scene.addChild(o);
        });
      }
    });*/

    /*this.lanaService.getScene().subscribe(scene => {
      console.log("He pedido la escena");
      if (scene) {
        console.log("setSecene");
        this.scene = scene;
        this.editor.setScene(this.scene);
      }

    });*/



    /*this.lanaService.getResetScene().subscribe(reset => {
      console.log("resetScene: ", reset);
      if (reset) {
        this.clearScene();
      }
    });*/



    //console.log("myModel:", this.stepComponent.model);
  }

  ngOnChanges(changes) {
    let myVRMode = changes.vrMode.currentValue;
    //this.visor.nativeElement.requestFullscreen();
    if (myVRMode) {
      $("body").css("--my-height", "100%");
      $("body").css("--my-width", "100%");
    }
    else {
      $("body").css("--my-height", "calc(100% - 133px)");
      $("body").css("--my-width", "70%");
    }
  }

  ngAfterViewInit() {
    $("#viewport").dblclick(function (event) {
      event.preventDefault();
    });

    //window.dispatchEvent(new Event(this.lanaContainer.nativeElement.));
  }

  VRMode() {
    //this.visor.nativeElement.requestFullscreen();
    if (this.vrMode) {
      let vr = this.lanaService.setVRMode();
      if (!vr) {
        let summary = this.translate.instant('VR_ERROR');
        let detail = this.translate.instant('Check_VR_device');
        this.messageService.add({ severity: 'error', summary: summary, detail: detail });
      }
    }

  }


  clickLana(event) {
    if (!this.vrMode) {
      let object = this.editor.getObjectToMove(event.clientX, event.clientY);
      console.log("getObjectToMove: ", object);
      if (object !== undefined && object !== null) {
        this.myObject = object.object;


        this.selectedObject = true;
        console.log("student: ", object);
      }
      else if (object === null) {
        this.selectedObject = false;
        console.log("no obj seleccionado");
        return;
        //$("#op").show(false);
      }
      if (event.type === "mousedown") {

        //emit name to playAction
        while (this.myObject.parent !== undefined) {
          if (this.myObject.name === "XY" || this.myObject.name === "YZ" || this.myObject.name === "XZ"
            || this.myObject.name === "X" || this.myObject.name === "Y" || this.myObject.name === "Z") {
            return;
          }
          let objectName = this.myObject.parent.name;
          this.playAction.emit(objectName);
          this.myObject = this.myObject.parent;
        }

      }
      else if (event.type === "dblclick") {
        if (object !== undefined && object !== null) {
          if (this.myObject.parent !== undefined) {
            let objectName = this.myObject.parent.name;
            this.editor.moveCameraToObject(objectName);
          }
        }
        else {
          this.editor.resetCamera();
        }
      }
    }
  }

  /*prevStep(event) {
    console.log("eventFromLana");
  }*/

  onGamepadEvent(event) {
    //console.log("eventFromLana", event);
  }

  unclickLana(event) {
    //this.editor.deleteHelpers();
    //this.selectedObject = false;
    //this.prevX = 0;
    //this.prevY = 0;
  }


  onMouseMove(event) {
    /*if (this.selectedObject) {
      console.log("mouse mouse: ", this.myObject);
      if (event.clientX > this.prevX) {
        this.myObject.object.getPosition.add(new LANA3D.Vector3(0.1, 0, 0));
      }
      else if (event.clientX < this.prevX) {
        this.myObject.object.getPosition.add(new LANA3D.Vector3(-0.1, 0, 0));
      }
      if (event.clientY > this.prevY) {
        this.myObject.object.getPosition.add(new LANA3D.Vector3(0, 0, 0.1));
      }
      else if (event.clientY > this.prevY) {
        this.myObject.object.getPosition.add(new LANA3D.Vector3(0, 0, -0.1));
      }

      this.prevX = event.clientX;
      this.prevY = event.clientY;

    }*/


  }

  handleChangeMove(e) {
    this.moveChecked = e.checked;
    //console.log(this.moveChecked);
    if (this.moveChecked) {
      this.rotateChecked = false;
      this.editor.setControlMode('translate');
    }
    else {
      this.rotateChecked = true;
      this.editor.setControlMode('rotate');
    }
  }

  handleChangeRotate(e) {
    this.rotateChecked = e.checked;
    //console.log(this.rotateChecked);
    if (this.rotateChecked) {
      this.moveChecked = false;
      this.editor.setControlMode('rotate');
    }
    else {
      this.moveChecked = true;
      this.editor.setControlMode('translate');
    }
  }

}
