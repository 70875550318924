import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, Response } from '@angular/http';
//import { MessageService } from '../services/message.service';
import { MessageService } from 'primeng/api';
import { StepComponent } from '../step/step.component';

import { StepService } from '../services/step.service';
import { ExerciseService } from '../services/exercise.service';
import { LanaService } from '../services/lana.service';
import { Step } from '../models/Step';
import { environment } from "./../../environments/environment";

import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';

import { ConfirmationService, TTResizableColumn } from "primeng/primeng";

import { Message } from 'primeng/api';
//import { $ } from 'protractor';
import * as $ from 'jquery';

import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { all } from 'q';
import { text } from '@angular/core/src/render3/instructions';
//import { runInThisContext } from 'vm';

const URL = 'http://localhost:3000/api/upload';

@Component({
  selector: 'app-step-menu',
  templateUrl: './step-menu.component.html',
  styleUrls: ['./step-menu.component.css']
})
export class StepMenuComponent implements OnInit {

  @Input() stepComponent: StepComponent;

  @Output() public model = new EventEmitter<string>();

  environment: any = environment;

  description: string;
  title: string;
  help: string;
  order: number;
  id: string;
  addTexture: boolean = false;
  addModel: boolean = false;
  daeModel: string;

  loading: boolean = false;
  nloaded: number = 0;


  selectedFile: string;
  //uploadedFiles: any[] = [];

  initialModels: any[];// = [];
  correctModels: any[];// = [];
  incorrectModels: any[];// = [];
  helpFiles: any[];
  stepOptions: any[] = [];
  selectedOptions: any[];
  lastSelectedOptions: any[] = [];

  uploadedFileInfo: any;
  uploadedDAE: any;
  textureList: any[] = [];
  texture: string = "";

  showModel: boolean = true;
  showHelp: boolean = false;
  helpType: string = "";

  helpFileSrc: string = '';
  tutorialNumPages: number = 0;
  currentTutorialPage: number;


  //correctModelsSubscription$: Observable<any>;

  exerciseId: string;

  msgs: Message[] = [];

  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo' });

  constructor(private http: Http,
    private messageService: MessageService,
    public stepService: StepService,
    public exerciseService: ExerciseService,
    private lanaService: LanaService,
    private router: Router,
    private confirmationService: ConfirmationService,
  ) {
    this.currentTutorialPage = 1;
  }

  /*connectCorrectModels(data) {
    this.correctModels
  }*/

  ngOnInit() {
    //get step data
    this.title = this.stepComponent.step.title;
    this.description = this.stepComponent.step.description;
    this.exerciseId = this.stepComponent.step.exerciseId;
    this.help = this.stepComponent.step.help;
    this.order = this.stepComponent.step.order;
    this.id = this.stepComponent.step.id;
    this.initialModels = this.stepComponent.step.initialModels;
    this.correctModels = this.stepComponent.step.correctModels;
    this.incorrectModels = this.stepComponent.step.incorrectModels;
    this.helpFiles = this.stepComponent.step.helpFiles;
    if (this.helpFiles === undefined) { this.helpFiles = [] }

    //this.stepOptions = this.correctModels;
    this.correctModels.forEach(element => {
      this.stepOptions.push(element);
    });
    // + this.incorrectModels;
    this.incorrectModels.forEach(element => {
      this.stepOptions.push(element);
    });

    //get unsorted options
    this.stepOptions = this.stepOptions.sort(function () { return Math.random() - Math.random() });

    this.loadModels();

  }

  hasOptions() {
    if (this.stepOptions.length > 0) {
      return true;
    }
    else return false;
  }

  /*onCorrectUpload(event) {
    this.uploadedFileInfo = JSON.parse(event.xhr.response);
    this.showModel = true;
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.correctModels.push(this.uploadedFileInfo);
    let selected = this.uploadedFileInfo.name;
    //this.lanaService.addModel(uploadedFileInfo.url);
    this.daeModel = selected.substring(0, selected.indexOf(".")) + ".dae";
    this.addModel = true;
  }*/

  /*onIncorrectUpload(event) {
    this.uploadedFileInfo = JSON.parse(event.xhr.response);
    this.showModel = false;
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.incorrectModels.push(this.uploadedFileInfo);
    let selected = this.uploadedFileInfo.name;
    //this.lanaService.addModel(uploadedFileInfo.url);
    this.daeModel = selected.substring(0, selected.indexOf(".")) + ".dae";
    this.addModel = true;

  }*/

  /*onHelpUpload(event) {
    this.uploadedFileInfo = JSON.parse(event.xhr.response);
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.helpFiles.push(this.uploadedFileInfo);

  }*/

  /*onInitialUpload(event) {
    this.uploadedFileInfo = JSON.parse(event.xhr.response);
    this.showModel = true;
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.initialModels.push(this.uploadedFileInfo);

    let selected = this.uploadedFileInfo.name;
    this.daeModel = selected.substring(0, selected.indexOf(".")) + ".dae";
    this.addModel = true;
  }*/

  /*onUploadDAE(event) {
    this.addModel = false;
    this.uploadedDAE = JSON.parse(event.xhr.response);
    this.uploadedDAE.url = environment.apiEndpoint + this.uploadedDAE.url;
    //this.initialModels.push(this.uploadedFileInfo);

    var mydaeObj = this.getDAEInfo(this.uploadedDAE.url);

    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(mydaeObj, "text/xml");
    var library_images = xmlDoc.getElementsByTagName("library_images")[0];
    if (library_images !== undefined) {
      var x = library_images.getElementsByTagName("image")[0];
      for (var i = 0; i < library_images.childElementCount; i++) {
        var image = library_images.getElementsByTagName("init_from")[i];
        let img = image.childNodes[0].nodeValue;
        this.textureList[i] = img.substring(img.indexOf('/') + 1);
      }
    }
    if (this.textureList.length > 0) {
      this.addTexture = true;
    }
    else {
      this.lanaService.addModel(this.uploadedDAE.url);
    }
  }*/

  viewHelpFile(file) {
    //get the type of help file
    this.helpFileSrc = file.url;
    if (file.url.indexOf(".pdf") > -1) {
      this.showHelp = true;
      this.helpType = "pdf";
    }
    else if (file.url.indexOf(".jpg") > -1) {
      this.showHelp = true;
      this.helpType = "image"
    }
    else if (file.url.indexOf(".mp3") > -1) {
      this.showHelp = true;
      this.helpType = "audio"
    }
    else if (file.url.indexOf(".mp4") > -1) {
      this.showHelp = true;
      this.helpType = "video"
    }

  }

  onInitialSelect(event) {
    for (let file in event.files) {
    }
  }

  /*onUploadTexture(event, i) {
    var index = this.textureList.indexOf(i);
    if (index > -1) {
      this.textureList.splice(index, 1);
    }

    if (this.textureList.length <= 0) {
      this.addTexture = false;
    }
    if (this.showModel) {
      this.lanaService.addModel(this.uploadedDAE.url);
    }
    this.texture = "";
  }*/

  showAddTexture(texture: string) {
    this.addTexture = true;
  }



  cancelAddTexture() {
    this.addTexture = false;
  }

  cancelAddDAE() {
    this.addModel = false;
  }

  hasInitialModels() {
    if (this.initialModels.length > 0) {
      return true;
    }
  }

  hasCorrectModels() {
    if (this.correctModels.length > 0) {
      return true;
    }
  }

  hasIncorrectModels() {
    if (this.incorrectModels.length > 0) {
      return true;
    }
  }

  hasHelpFiles() {
    if (this.helpFiles.length > 0) {
      return true;
    }
  }

  /*save() {
    let newStep: Step;
    newStep = this.stepComponent.step;
    newStep.title = this.title;
    newStep.description = this.description;
    newStep.exerciseId = this.exerciseId;
    newStep.help = this.help;
    newStep.initialModels = this.initialModels;
    newStep.correctModels = this.correctModels;
    newStep.incorrectModels = this.incorrectModels;
    newStep.helpFiles = this.helpFiles;
    newStep.id = this.id;
    this.stepService.editStep(newStep);

    //To save update Date
    this.exerciseService.getByExerciseId(this.exerciseId).subscribe(
      exercise => {
        let myExercise = exercise[0];
        this.exerciseService.editExercise(myExercise);
      }
    );


    let summary = "Saved";
    let detail = "The step was correctly saved";
    this.messageService.add({ severity: 'success', summary: summary, detail: detail });
    //this.showInfo(summary, detail);
  }*/

  /*confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.delete();
      }
    });
  }

  delete() {
    let stepId = this.stepComponent.step.id;
    let stepOrder = this.stepComponent.step.order;
    this.stepService.deleteStep(stepId);

    //TODO: Modificar orden de los pasos posteriores:
    this.stepService.renumberSteps(this.exerciseId, this.order);
    this.stepComponent.update();
    let summary = "Saved";
    let detail = "The step was correctly deleted";
    this.messageService.add({ severity: 'success', summary: summary, detail: detail });
    //this.showInfo(summary, detail);
  }*/

  /*showInfo(summary: string, detail: string) {
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: summary, detail: detail });
  }*/


  /*deleteInitialModel(model) {
    var index = this.initialModels.indexOf(model);
    if (index > -1) {
      this.initialModels.splice(index, 1);
      this.lanaService.resetScene();
      this.ngOnInit();
    }
  }*/

  /*deleteCorrectModel(model) {
    var index = this.correctModels.indexOf(model);
    if (index > -1) {
      this.correctModels.splice(index, 1);
      this.lanaService.resetScene();
      this.ngOnInit();
    }
  }*/

  /*deleteIncorrectModel(model) {
    var index = this.incorrectModels.indexOf(model);
    if (index > -1) {
      this.incorrectModels.splice(index, 1);
    }
  }

  deleteHelpFiles(file) {
    var index = this.helpFiles.indexOf(file);
    if (index > -1) {
      this.helpFiles.splice(index, 1);
    }
  }*/

  getJson(url) {
    return JSON.parse($.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      global: false,
      async: false,
      success: function (data) {
        return data;
      }
    }).responseText);
  }

  getDAEInfo(url) {
    return $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      global: false,
      async: false,
      success: function (data) {
        return data;
      }
    }).responseText;
  }

  loadModels() {
    let numModels = this.initialModels.length;// + this.correctModels.length;
    let sum = 100 / numModels;
    let nModel = 1;
    let matrix;

    if (this.initialModels.length > 0) {
      for (let initialModel of this.initialModels) {
        let params = {
          this: this,
          nModel: nModel,
          numModels: numModels
        }
        this.loading = true;
        let mydaeObj = initialModel.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        if (initialModel.matrixWorld === undefined || initialModel.matrixWorld === null) {
          matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
        }
        else {
          matrix = initialModel.matrixWorld;
        }
        let rename = mydaeObj.substring(mydaeObj.indexOf("/api/"));
        mydaeObj = environment.apiEndpoint + rename;
        console.log("mydaeObj; ", mydaeObj);
        this.lanaService.addModel(mydaeObj, matrix, this.updateProgessBar, params);
      }
    }
    /*if (this.correctModels.length > 0) {
      for (let correctModel of this.correctModels) {
        let mydaeObj = correctModel.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        this.lanaService.addModel(mydaeObj);
      }
    }*/
  }

  checkStep() {

    if (this.lastSelectedOptions.length !== this.correctModels.length) {
      let summary = "Error";
      let detail = "It is not completed";
      this.messageService.add({ severity: 'error', summary: summary, detail: detail });
    }
    else {
      let itIs = false;
      for (let correct of this.correctModels) {
        for (let selected of this.lastSelectedOptions) {
          if (correct.name === selected.name) {
            //itIs = true;
            itIs = this.checkMatrixWorld(correct, selected);
          }
        }
        if (!itIs) {
          let summary = "Error";
          let detail = "Incorrect";
          this.messageService.add({ severity: 'error', summary: summary, detail: detail });
          break;
        }
      }
      if (itIs) {
        let summary = "Perfect!";
        let detail = "Your answer is correct";
        this.messageService.add({ severity: 'success', summary: summary, detail: detail });
      }
    }
  }

  checkMatrixWorld(correct, selected) {
    let isOk = true;
    let res;
    let currentSelecMW = this.lanaService.getMatrixWorld(selected.name);
    currentSelecMW.elements.forEach(function (element, index) {
      res = element - correct.matrixWorld.elements[index];
      if (index < 12) {
        if (res > 0.20 || res < -0.20) {
          isOk = false;
        }
      }
      else {
        if (res > 2 || res < -2) {
          isOk = false;
        }
      }
    });
    /*if (!isOk) {
      console.log("No colocado correctamente");
    }*/
    return isOk;
  }

  handleOption(event) {
    let selected = event.value;
    if (selected.length > this.lastSelectedOptions.length) {
      //add model
      let isEqual
      for (let modelS of selected) {
        isEqual = false
        for (let modelLS of this.lastSelectedOptions) {
          if (modelS.name === modelLS.name) {
            isEqual = true;

          }
        }
        if (!isEqual) {
          //add
          this.loading = true;
          let params = {
            this: this,
            nModel: 1,
            numModels: 1
          }
          let matrix;
          let mydaeObj = modelS.url;
          mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
          /*if (modelS.matrixWorld === undefined) {
            matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
          }
          else {
            matrix = modelS.matrixWorld;
          }*/
          //no aplicar la matrid de transformación, lo tiene que colocar el estudiante
          matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
          this.lanaService.addModel(mydaeObj, matrix, this.updateProgessBar, params);
        }
      }
    }
    else if (selected.length < this.lastSelectedOptions.length) {
      // remove model
      let isEqual
      for (let modelLS of this.lastSelectedOptions) {
        isEqual = false
        for (let modelS of selected) {
          if (modelS.name === modelLS.name) {
            isEqual = true;

          }
        }
        if (!isEqual) {
          //add
          let mydaeObj = modelLS.url;
          mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
          this.lanaService.deleteModel(mydaeObj);
        }
      }
    }
    this.lastSelectedOptions = selected;

  }

  updateProgessBar(params) {

    params.this.nloaded = params.this.nloaded + 1;
    if (params.this.nloaded === params.numModels) {
      params.this.loading = false;
      params.this.nloaded = 0;
    }

  }

  prevTutorialPage() {
    this.currentTutorialPage = this.currentTutorialPage - 1;
  }

  nextTutorialPage() {
    this.currentTutorialPage = this.currentTutorialPage + 1;
  }

  checkFirsTutorialPage() {
    if (this.currentTutorialPage === 1) {
      return true;
    }
  }

  checkLastTutorialPage() {
    if (this.currentTutorialPage === this.tutorialNumPages) {
      return true;
    }
  }

  stateRender(event) {
    let info = event._pdfInfo;
    this.tutorialNumPages = info.numPages;
  }

}
